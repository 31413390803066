

































































































































































































































































































































































































import Component, { mixins } from 'vue-class-component';

import WidgetMixins from '../../../mixins/WidgetMixins';
import DialogMixins from '../../../mixins/DialogMixins';
import { Getter, namespace } from 'vuex-class';
import {
  IContact,
  IGroup,
  IGroupContact,
  IMessage,
  IPaginate,
} from '@/types/types';
import { loadView, loadWidget } from '@/utils/helpers';
import { Watch } from 'vue-property-decorator';
import { debounce } from 'lodash';

const contactModule = namespace('contact');
const groupModule = namespace('group');
@Component({
  name: 'Index',
  components: {
    InternetConnection: loadWidget('pages/InternetConnection'),
    ToolBar: loadWidget('widgets/ToolBar'),
    SnackBar: loadWidget('widgets/SnackBar'),
    EmptyPage: loadWidget('pages/EmptyPage'),
    ProgressBar: loadWidget('widgets/CircularProgressLoader'),
    AddGroupDialog: loadView('pages/groups/dialogs/AddGroupDialog'),
    EditGroupDialog: loadView('pages/groups/dialogs/EditGroupDialog'),
    DeleteDialog: loadWidget('widgets/DeleteDialog'),
    BulkUploadDialog: loadWidget('widgets/BulkUpload'),
    AddContactToGroupDialog: loadView(
      'pages/groups/dialogs/AddContactToGroupDialog'
    ),
    SendSMSDialog: loadView(`pages/groups/dialogs/SendSMSDialog`),
    AddSMSSenderID: loadWidget(`widgets/AddSenderID`),
  },
  filters: {},
})
export default class Index extends mixins(WidgetMixins, DialogMixins) {
  @groupModule.Getter('getGroupDetails') details!: IGroup;
  @Getter('getCreditBalance') creditBalance!: number;
  @groupModule.Getter('getAllGroups') groups!: ReadonlyArray<IGroup>;
  @groupModule.Getter('getSearchResults') searchResults!: ReadonlyArray<IGroup>;
  @Getter('getResetFormState') resetFormState!: boolean;
  @groupModule.Getter('getPagination') paginate!: Pick<
    IPaginate,
    'page' | 'total' | 'itemsPerPage'
  > & { limit: number };

  @contactModule.Getter('getAllContacts') contacts!: ReadonlyArray<IContact>;

  group: IGroup = {
    id: 0,
    name: '',
    description: '',
  };

  options: IPaginate = {
    itemsPerPage: 30,
    page: 1,
    total: 0,
  };

  search = '';
  isSearching = false;

  assignedContacts: IContact[] = [];

  headers: Array<{ text: string; value: string; align?: string }> = [
    {
      text: '',
      value: 'avatar',
    },

    {
      text: 'Name',
      value: 'name',
    },
    {
      text: 'Description',
      value: 'description',
    },
    {
      text: 'Contacts',
      value: 'groupContactsId',
      align: 'center',
    },
    {
      text: 'Actions',
      align: 'center',
      value: 'actions',
    },
  ];

  searchText(term: string): void {
    this.$store.dispatch('group/searchGroups', { query: term?.trim() });
  }

  handleOnSearchTextChange = debounce(this.searchText, 1000);

  @Watch('search')
  onSearchTermChange(term: string): void {
    this.handleOnSearchTextChange(term);
  }

  openContactDialog(
    group: IGroup & { groupContactsId: Array<IGroupContact> }
  ): void {
    this.open('contacts');
    this.$store.dispatch('contact/list', { page: 1, limit: 30 });
    this.group = { ...group };
    this.assignedContacts = group.groupContactsId.map(
      (groupContact) => groupContact.contactId as IContact
    );
  }

  @Watch('options')
  onOptionsChange(payload: IPaginate): void {
    this.$store.dispatch('group/list', {
      page: payload.page,
      limit: payload.itemsPerPage,
    });
  }

  addGroup(payload: IContact): void {
    this.$store.dispatch('group/create', payload);
  }

  editContact(payload: IContact): void {
    this.$store.dispatch('contact/update', payload);
  }

  editGroup(payload: IGroup): void {
    this.$store.dispatch('group/update', payload);
  }

  deleteGroup(): void {
    this.$store.dispatch('group/delete', this.details?.id);
  }

  addContactsToGroup(contacts: IContact[]): void {
    let payload = {
      contacts: contacts.map((contact) => contact.id),
      groupId: this.group.id,
    };
    this.$store.dispatch('group/assignContactsToGroup', payload);
  }

  copyContact(phone: string): void {
    this.$copyText(phone).then(
      () => {
        this.$store.dispatch('snackBarMessage', `Copied`, { root: true });
        this.$store.dispatch('snackBarVisibility', true, { root: true });
      },
      () => {
        this.$store.dispatch('snackBarMessage', `Unable to copy contact`, {
          root: true,
        });
        this.$store.dispatch('snackBarVisibility', true, { root: true });
      }
    );
  }

  bulkUpload(file: File): void {
    let formData = new FormData();
    formData.append('file', file, file.name);
    const id = this.$store.getters['group/getGroupDetails']?.id;
    if (id) {
      this.$store.dispatch('group/bulkUpload', {
        id,
        file: formData,
      });
    } else {
      this.$store.dispatch(
        'snackBarMessage',
        `An error occurred trying to upload contacts to this group`,
        {
          root: true,
        }
      );
      this.$store.dispatch('snackBarVisibility', true, { root: true });
    }
  }

  sendSMS({ body: { to, type, message, from } }: { body: IMessage }): void {
    const payload = {
      body: {
        messages: to?.split(',')?.map((phone) => {
          return {
            to: phone,
            message,
            from,
            type,
          };
        }),
      },
    };
    this.$store.dispatch('sms/sendBulkSMS', payload);
  }

  created(): void {
    this.$store.dispatch('group/list', {
      page: 1,
      limit: 30,
    });
    this.$store.dispatch('checkCreditBalance');
    this.$store.dispatch('auth/me');
  }
}
